.App {
  
  
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #414650;
}

.App-header {
  background-color: #282c34;
  padding: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid #EEE;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.body {
  padding: 25px;     
  flex: 1;   
  overflow: hidden;
  position: relative;
}

#typer-container {
  mask: linear-gradient(transparent 10%, #414650, transparent 90%);
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
}

#typer {
  font-family:monospace;
    -webkit-tap-highlight-color:transparent;
    outline-style: none;
    display: flex;
    flex-wrap: wrap;    
    margin: 30vh 0;
    cursor: none;
}

footer {
  background-color: #282c34;
  padding: 25px;
  border-top: 1px solid #EEE;
  font-size: .8rem;
}